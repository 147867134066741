export const environment = {
  production: true,
  serviceUrl: 'https://api.benefit-ads.com',
  vastUrl: 'https://',
  cdnUrl: 'https://cdn.benefit-ads.com',
  a2vUrl: 'https://a2v.benefit-ads.com',
  platformTitle: 'Benefit Ads',
  defaultBidderCode: 'benefitads',
  vastEndpoint: 'https://ads.benefit-ads.com/vast',
  exchangeDomainUrl: 'https://ads.benefit-ads.com/',
  defaultAdsTxtDomain: 'benefit-ads.com',
  defaultAdsTxtTagID: '000000',
  sellerDomainLabel: 'benefitads',
  logoUrl: 'https://benefit-ads-media.s3.eu-north-1.amazonaws.com/logo-benefit-ads-black.png',
  logoLightUrl: 'https://benefit-ads-media.s3.eu-north-1.amazonaws.com/logo-benefitads-white.png',
  faviconUrl: 'https://benefit-ads-media.s3.eu-north-1.amazonaws.com/ba+-+favicon.ico' 
};
